// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bonus-system-js": () => import("../src/pages/bonus-system.js" /* webpackChunkName: "component---src-pages-bonus-system-js" */),
  "component---src-pages-certificates-js": () => import("../src/pages/certificates.js" /* webpackChunkName: "component---src-pages-certificates-js" */),
  "component---src-pages-contacts-js": () => import("../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-cookie-js": () => import("../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-faq-js": () => import("../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gift-cards-js": () => import("../src/pages/gift-cards.js" /* webpackChunkName: "component---src-pages-gift-cards-js" */),
  "component---src-pages-groomer-js": () => import("../src/pages/groomer.js" /* webpackChunkName: "component---src-pages-groomer-js" */),
  "component---src-pages-groomers-js": () => import("../src/pages/groomers.js" /* webpackChunkName: "component---src-pages-groomers-js" */),
  "component---src-pages-gui-js": () => import("../src/pages/gui.js" /* webpackChunkName: "component---src-pages-gui-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-data-processing-js": () => import("../src/pages/personal-data-processing.js" /* webpackChunkName: "component---src-pages-personal-data-processing-js" */),
  "component---src-pages-personal-discount-js": () => import("../src/pages/personal-discount.js" /* webpackChunkName: "component---src-pages-personal-discount-js" */),
  "component---src-pages-public-offer-js": () => import("../src/pages/public-offer.js" /* webpackChunkName: "component---src-pages-public-offer-js" */),
  "component---src-pages-services-list-js": () => import("../src/pages/services-list.js" /* webpackChunkName: "component---src-pages-services-list-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-site-map-js": () => import("../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-stub-js": () => import("../src/pages/stub.js" /* webpackChunkName: "component---src-pages-stub-js" */),
  "component---src-pages-thanks-js": () => import("../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-vacancies-js": () => import("../src/pages/vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */),
  "component---src-pages-vacancy-js": () => import("../src/pages/vacancy.js" /* webpackChunkName: "component---src-pages-vacancy-js" */),
  "component---src-pages-years-packages-js": () => import("../src/pages/years-packages.js" /* webpackChunkName: "component---src-pages-years-packages-js" */)
}

